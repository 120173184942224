import * as React from "react";
import RichText from "prismic-reactjs/src/Component";

const CenteredBlock = ({title, content, buttons}) => {
    return (
        <section className="section section--with-padding section--centered section-centered">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="section__title text-center">{title}</h2>
                        <div className="section__content section__content--vertical section__content--fixed-width">
                            <div className="section-centered__icons">

                            </div>

                            {content ? RichText.render(content.raw) : null}

                            {buttons ? <div className="section-centered__buttons">{RichText.render(buttons.raw)}</div> : null }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CenteredBlock;
