import * as React from "react";
import RichText from "prismic-reactjs/src/Component";

const FeatureItem = (props) => {
    return (
        <div className="feature-item">
            <div className="feature-item__icon">
                <img src={props.icon.url} alt={props.subtitle} />
            </div>
            <div className="feature-item__metas">
                <h3 className="feature-item__title">
                    {props.subtitle}
                </h3>
                {RichText.render(props.description.raw)}
            </div>
        </div>
    )
}

export default FeatureItem;
