import * as React from "react";
import RichText from "prismic-reactjs/src/Component";
import Img from "gatsby-image";


const BlockImageWithText = ({title, text, image}) => {
    return (
        <section className="section section--with-padding section-image-text">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section__content">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="section-image-text__content-wrapper">
                                        <div>
                                            {title ? <h2>{title}</h2> : null}
                                            {RichText.render(text.raw)}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <Img fluid={image.fluid} alt={image.alt} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlockImageWithText;
