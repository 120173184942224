import * as React from "react";
import FeatureItem from "./FeatureItem";
import Overlay from "../Overlay";
import {useContext, useState} from "react";
import Context, { ContextProvider } from "../Context";
import {RichText} from "prismic-reactjs";
import Img from "gatsby-image";

const FEATURE_ITEM_COL_LAYOUT = "col-12 col-lg-6";

const Features = ({title, items}) => {

    const context = useContext(Context);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [modalImage, setModalImage] = useState('');

    return (
        <ContextProvider value={context}>
            {modalVisible ?
                <Overlay noPadding={true} onCloseHandler={() => setModalVisible(false)} contentBoxWidth="63rem">
                    <div className="row gx-0">
                        <div className="col-md-6">
                            <div className="contact-form-wrapper">
                                <div className="overlay__title">
                                    {modalTitle}
                                </div>

                                {modalContent}
                            </div>
                        </div>
                        <div className="col-md-6">
                            {modalImage}
                        </div>
                    </div>
                </Overlay>
                :
                null
            }

            <section className="section section--with-padding section-features">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="section__title text-center">{title}</h2>
                            <div className="section__content">
                                <div className="row">
                                    {items.map( (item, index) =>
                                        <div key={index} className={FEATURE_ITEM_COL_LAYOUT} onClick={() => {
                                            setModalVisible(true)
                                            setModalTitle(item.subtitle)
                                            setModalContent(RichText.render(item.modal_text_content.raw))
                                            setModalImage(<Img fluid={item.modal_image.fluid} alt={item.modal_image.alt} />)
                                        }}>
                                            <FeatureItem {...item} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ContextProvider>
    )
}

export default Features;
