import * as React from "react";
import classNames from "classnames";

const PoweredBySlider = ({images, reverse}) => {

    const shuffleArray = (array) => {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            const rand = Math.random();

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }


        return array;
    }

    images = shuffleArray(images);

    const sliderClassnames = classNames(
        'slider',
        {'slider--reverse': reverse}
    );

    const slideWidth = 223;
    const slideHeight = 328;

    return (
        <div className="powered-by-slider">
            <div className={sliderClassnames}>
                <div className="slide-track">
                    {images.map((image, index) => {
                        return <div key={index} className="slide"><img loading={"lazy"} src={image.image_poster.url} height={slideHeight} width={slideWidth} alt=""/></div>
                    })}
                    {images.map((image, index) => {
                        return <div key={index} className="slide"><img loading={"lazy"}src={image.image_poster.url} height={slideHeight} width={slideWidth} alt=""/></div>
                    })}
                    {images.map((image, index) => {
                        return <div key={index} className="slide"><img loading={"lazy"}src={image.image_poster.url} height={slideHeight} width={slideWidth} alt=""/></div>
                    })}
                    {images.map((image, index) => {
                        return <div key={index} className="slide"><img loading={"lazy"} src={image.image_poster.url} height={slideHeight} width={slideWidth} alt=""/></div>
                    })}
                </div>
            </div>
        </div>
    )

}

export default PoweredBySlider
