import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/Layout";
import CenteredBlock from "../components/CenteredBlock";
import BlockImageWithText from "../components/BlockImageWithText";
import Features from "../components/Features/Features";
import Carousel from "../components/Carousel";
import PoweredBy from "../components/PoweredBy/PoweredBy";
import {Helmet} from "react-helmet";


const IndexPage = ({location, path, data}) => {
    if (!data) return null
    const slices = data.prismicHomepage.data.body;

    const accentColor = "#006D78";

    return (
        <Layout location={location} path={path}>
            <Helmet style={[{
                "cssText": `
                    :root {
                    --accent-color: ${accentColor};
                    }
                `
            }]} />
            {slices.map((slice, index) => {
                switch (slice.slice_type) {
                    case 'carousel':
                        return <div key={index} id={slice.primary?.identifier}><Carousel items={slice.items} /></div>;
                    case 'featured_image':
                        return <div key={index} id={slice.primary?.identifier}><BlockImageWithText key={index} title={slice.primary.title ? slice.primary.title.text : ''} text={slice.primary.text_content} image={slice.primary.featured_image} /></div>;
                    case 'features':
                        return <div key={index} id={slice.primary?.identifier}><Features key={index} title={slice.primary.title.text} items={slice.items}  /></div>;
                    case 'sliding_gallery':
                        return <div key={index} id={slice.primary?.identifier}><PoweredBy title={slice.primary.section_title.text} subtitle={slice.primary.section_subtitle} images={slice.items} /></div>
                    case 'centered_block':
                        return <div key={index} id={slice.primary?.identifier}><CenteredBlock key={index} title={slice.primary.title.text} image={[]} content={slice.primary.text_content} buttons={slice.primary.buttons}/></div>;
                    default:
                        return null;
                }
            })}
        </Layout>
    )

}
export default IndexPage

export const query = graphql`
    query MyIndexQuery {
        prismicHomepage(id: {}) {
          id
          data {
            body {
              ... on PrismicHomepageBodyCenteredBlock {
                id
                items {
                  images {
                    url
                    alt
                  }
                }
                primary {
                  identifier
                  title {
                    text
                    raw
                    html
                  }
                  text_content {
                    text
                    raw
                    html
                  }
                  buttons {
                    text
                    raw
                    html
                  }
                }
                slice_type
              }
              ... on PrismicHomepageBodyCarousel {
                id
                items {
                  image_slide {
                    url
                    alt
                    fluid(maxWidth: 1280, maxHeight: 720) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  topic
                  title
                  details
                  casting
                }
                primary {
                  identifier
                }
                slice_type
              }
              ... on PrismicHomepageBodyFeaturedImage {
                id
                primary {
                  identifier
                  title {
                    text
                    raw
                    html
                  }
                  text_content {
                    text
                    raw
                    html
                  }
                  featured_image {
                    url
                    alt
                    fluid(maxWidth: 648, maxHeight: 364) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                }
                slice_type
              }
              ... on PrismicHomepageBodyFeatures {
                id
                items {
                  modal_text_content {
                    html
                    text
                    raw
                  }
                  modal_image {
                    alt
                    url
                    fluid(maxWidth: 533, maxHeight: 570) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  icon {
                    alt
                    url
                  }
                  description {
                    html
                    text
                    raw
                  }
                  subtitle
                }
                slice_type
                primary {
                  identifier
                  title {
                    html
                    raw
                    text
                  }
                }
              }
              ... on PrismicHomepageBodySlidingGallery {
                id
                slice_type
                primary {
                  identifier
                  section_title {
                    html
                    raw
                    text
                  }
                  section_subtitle {
                    html
                    raw
                    text
                  }
                }
                items {
                  image_poster {
                    alt
                    url
                  }
                }
              }
            }
          }
        }
    }
`;
