import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination, Thumbs, Autoplay } from "swiper";


import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/thumbs";

import Img from "gatsby-image";

const Carousel = ({items}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <section className="section">
            <Swiper
                style={{
                    "--swiper-pagination-bullet-horizontal-gap": "6px",
                    "--swiper-pagination-bullet-size": "12px",
                    "--swiper-pagination-color": "#fff",
                    "--swiper-pagination-bullet-inactive-color": "#fff",
                    "--swiper-pagination-bullet-inactive-opacity": ".3",
                }}
                initialSlide={2}
                slidesPerView={1}
                spaceBetween={0}
                centeredSlides={true}
                navigation={false}
                pagination={{
                    clickable: true
                }}
                autoplay={{
                    "delay": 5000,
                    "disableOnInteraction": false
                }}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Pagination, Thumbs, Autoplay]}
                className="main-carousel"
                breakpoints={{
                    820: {
                        slidesPerView: 1.3,
                    },
                }}
            >
                {items.map((item, index) =>
                    <SwiperSlide key={index}>
                        <div className="carousel-item">
                            <Img fluid={item.image_slide.fluid} alt={item.image_slide.alt} />

                            <div className="carousel-item-metas">
                                <div className="carousel-item-metas__title">
                                    {item.title}
                                </div>
                                <ul className="carousel-item-metas__infos">
                                    <li>{item.topic}</li>
                                    <li>{item.casting}</li>
                                    <li>{item.details}</li>
                                </ul>
                            </div>
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>


            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={0}
                slidesPerView={7}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Pagination, Thumbs]}
                className="main-carousel-thumbnails"
            >
                {items.map((item, index) =>
                    <SwiperSlide key={index}>
                        <div className="carousel-thumbnail">
                            <Img fluid={item.image_slide.fluid} alt={item.image_slide.alt} />
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>
        </section>
    )
}

export default Carousel;

